header {
    background-color: rgb(0, 119, 201);
    height: 48px;
    color: white;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

main {
    padding: 20px;
    min-height: calc(100vh - 96px);
}

footer {
    background-color: #424242;
    height: 48px;
    color: white;
    padding-left: 20px;
    display: flex;
    align-items: center;
}